<template>
  <a-spin :spinning="admins_manager.loading">
    <sdPageHeader title="Yöneticiler">
      <template #buttons>
        <div class="page-header-actions">
          <sdButton
            size="small"
            type="primary"
            @click="create_admin_manager.visible = true"
          >
            <sdFeatherIcons
              type="plus"
              size="14"
            />
            Yönetici Ekle
          </sdButton>
          <sdButton
            size="small"
            type="primary"
            @click="roles_manager.load()"
          >
            <sdFeatherIcons
              type="list"
              size="14"
            />
            Roller
          </sdButton>
        </div>
      </template>
    </sdPageHeader>
    <Main>
      <sdCards headless>
        <a-table
          :data-source="admins_manager.admins"
          :columns="admins_manager.columns"
          row-key="id"
        >
          <template #is_active_render="{ record }">
            <sdFeatherIcons
              v-if="record.is_active"
              type="check-circle"
            />
            <sdFeatherIcons
              v-else
              type="circle"
            />
          </template>
          <template #edit_admin_render="{ record }">
            <sdButton
              size="small"
              type="primary"
              @click="edit_admin_manager.open(record.id)"
            >
              Düzenle
            </sdButton>
          </template>
        </a-table>
      </sdCards>
    </Main>
  </a-spin>

  <a-modal
    v-model:visible="create_admin_manager.visible"
    title="Yönetici Ekle"
    :confirm-loading="create_admin_manager.submitting"
    ok-text="Ekle"
    cancel-text="Kapat"
    @ok="create_admin_manager.submit"
    @cancel="create_admin_manager.visible = false"
  >
    <a-form layout="vertical">
      <a-form-item label="Adı">
        <a-input v-model:value="create_admin_manager.state.first_name" />
      </a-form-item>
      <a-form-item label="Soyadı">
        <a-input v-model:value="create_admin_manager.state.last_name" />
      </a-form-item>
      <a-form-item label="E-Posta">
        <a-input v-model:value="create_admin_manager.state.email" />
      </a-form-item>
      <a-form-item label="Rol Seçiniz">
        <a-select
          v-model:value="create_admin_manager.state.role_id"
          placeholder="Lütfen Seçiniz"
          style="width: 100%"
        >
          <a-select-option
            v-for="item in admins_manager.roles"
            :key="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal
    v-model:visible="edit_admin_manager.visible"
    title="Yönetici Düzenle"
    :confirm-loading="edit_admin_manager.submitting"
    ok-text="Kaydet"
    cancel-text="Kapat"
    @ok="edit_admin_manager.submit"
    @cancel="edit_admin_manager.visible = false"
  >
    <a-form layout="vertical">
      <a-form-item label="Rol Seçiniz">
        <a-select
          v-model:value="edit_admin_manager.state.role_id"
          placeholder="Lütfen Seçiniz"
          style="width: 100%"
        >
          <a-select-option
            v-for="item in admins_manager.roles"
            :key="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Aktif?">
        <a-switch
          v-model:checked="edit_admin_manager.state.is_active"
          checked-children="Aktif"
          un-checked-children="Pasif"
        />
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal
    v-model:visible="roles_manager.visible"
    :footer="null"
  >
    <template #title>
      <sdButton
        size="small"
        type="primary"
        @click="create_role_manager.visible = true"
      >
        <sdFeatherIcons
          type="plus"
          size="14"
        />
        Rol Ekle
      </sdButton>
    </template>
    <a-table
      :data-source="roles_manager.roles"
      :columns="roles_manager.columns"
      row-key="id"
      :pagination="false"
    >
      <template #list_permissions_render="{ record }">
        <sdButton
          size="small"
          type="primary"
          @click="permissions_manager.load(record.id)"
        >
          Roller
        </sdButton>
      </template>
    </a-table>
  </a-modal>

  <a-modal
    v-model:visible="create_role_manager.visible"
    title="Rol Ekle"
    :confirm-loading="create_role_manager.submitting"
    ok-text="Ekle"
    cancel-text="Kapat"
    @ok="create_role_manager.submit"
    @cancel="create_role_manager.visible = false"
  >
    <a-form layout="vertical">
      <a-form-item label="Rol Adı">
        <a-input v-model:value="create_role_manager.state.name" />
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal
    v-model:visible="permissions_manager.visible"
    title="İzinler"
    :footer="null"
  >
    <a-table
      :data-source="permissions_manager.permissions"
      :columns="permissions_manager.columns"
      row-key="id"
      :pagination="false"
    >
      <template #update_role_permission_render="{ record }">
        <a-switch
          v-model:checked="record.checked"
          checked-children="Aktif"
          un-checked-children="Pasif"
          :loading="permissions_manager.checked_loadings[record.id] === true"
          @change="permissions_manager.handleChange(record.id)"
        />
      </template>
    </a-table>
  </a-modal>
</template>
<script>
import { defineComponent, onMounted, reactive } from 'vue'
import restMethods from '@/utility/restMethods'
import { Main } from '@/view/styled'

export default defineComponent({
  name: 'AdminList',
  components: {
    Main,
  },
  setup() {

    const admins_manager = reactive({
      loading: false,
      roles: [],
      admins: [],
      columns: [
        {
          title: 'İsim',
          dataIndex: 'name',
        },
        {
          title: 'E-Posta',
          dataIndex: 'email',
        },
        {
          title: 'Rolü',
          dataIndex: 'role',
        },
        {
          title: 'Aktif?',
          slots: {
            customRender: 'is_active_render',
          },
        },
        {
          slots: {
            customRender: 'edit_admin_render',
          },
        },
      ],
      load: () => {
        admins_manager.loading = true
        restMethods.postForNoc('/get-admin-list').then(response => {
          if ( ! response.hasError()) {

            const data = response.getData()

            admins_manager.roles = data.roles
            admins_manager.admins = data.admins

          }
          admins_manager.loading = false
        })
      },
    })

    const create_admin_manager = reactive({
      visible: false,
      submitting: false,
      state: {
        first_name: '',
        last_name: '',
        email: '',
        role_id: '',
      },
      submit: () => {
        create_admin_manager.submitting = true
        restMethods.postForNoc('/create-admin-submit', create_admin_manager.state, { show_success: true }).then(response => {
          if ( ! response.hasError()) {

            create_admin_manager.state.first_name = ''
            create_admin_manager.state.last_name = ''
            create_admin_manager.state.email = ''
            create_admin_manager.state.role_id = undefined

            create_admin_manager.visible = false

            admins_manager.load()

          }

          create_admin_manager.submitting = false

        })
      },
    })

    const edit_admin_manager = reactive({
      active_admin_id: '',
      visible: false,
      submitting: false,
      state: {
        role_id: undefined,
        is_active: false,
      },
      open: (id) => {

        restMethods.postForNoc('/edit-admin-open/' + id).then(response => {
          if ( ! response.hasError()) {

            const data = response.getData()

            edit_admin_manager.state.role_id = data.role_id
            edit_admin_manager.state.is_active = data.is_active

            edit_admin_manager.active_admin_id = id

            edit_admin_manager.visible = true

          }
        })

      },
      submit: () => {
        edit_admin_manager.submitting = true
        restMethods.postForNoc('/edit-admin-submit/' + edit_admin_manager.active_admin_id, edit_admin_manager.state, { show_success: true }).then(response => {
          if ( ! response.hasError()) {

            edit_admin_manager.state.role_id = undefined
            edit_admin_manager.state.is_active = false

            edit_admin_manager.visible = false

            admins_manager.load()

          }

          edit_admin_manager.submitting = false

        })
      },
    })

    const roles_manager = reactive({
      visible: false,
      roles: [],
      columns: [
        {
          title: 'Rol Adı',
          dataIndex: 'name',
        },
        {
          key: 'list_permissions',
          slots: {
            customRender: 'list_permissions_render',
          },
        },
      ],
      load: () => {
        restMethods.postForNoc('/get-admin-role-list').then(response => {
          if ( ! response.hasError()) {

            const data = response.getData()

            roles_manager.roles = data.roles

            roles_manager.visible = true

          }
        })
      },
    })

    const create_role_manager = reactive({
      visible: false,
      submitting: false,
      state: {
        name: '',
      },
      submit: () => {
        create_role_manager.submitting = true
        restMethods.postForNoc('/create-admin-role-submit', create_role_manager.state, { show_success: true }).then(response => {
          if ( ! response.hasError()) {

            create_role_manager.state.name = ''

            create_role_manager.visible = false

            roles_manager.load()

          }

          create_role_manager.submitting = false

        })
      },
    })

    const permissions_manager = reactive({
      active_role_id: '',
      visible: false,
      checked_loadings: [],
      permissions: [],
      columns: [
        {
          title: 'İzin Kodu',
          dataIndex: 'code',
        },
        {
          key: 'update_role_permission',
          slots: {
            customRender: 'update_role_permission_render',
          },
        },
      ],
      load: (roleId) => {
        restMethods.postForNoc('/get-admin-role-permission-list/' + roleId).then(response => {
          if ( ! response.hasError()) {

            permissions_manager.permissions = response.getData().permissions

            permissions_manager.visible = true

            // daha sonra kullanmak üzere
            permissions_manager.active_role_id = roleId

          }
        })
      },
      reload: () => {
        return restMethods.postForNoc('/get-admin-role-permission-list/' + permissions_manager.active_role_id).then(response => {
          if ( ! response.hasError()) {
            permissions_manager.permissions = response.getData().permissions
          }
        })
      },
      handleChange: (permissionId) => {
        permissions_manager.checked_loadings[permissionId] = true
        restMethods.postForNoc('/admin-role-permission-toggle/' + permissions_manager.active_role_id + '/' + permissionId, {}, { show_success: true }).then(response => {
          if ( ! response.hasError()) {

          }
          permissions_manager.reload().then(() => {
            permissions_manager.checked_loadings[permissionId] = false
          })
        })
      },
    })

    onMounted(() => {
      admins_manager.load()
    })

    return {
      admins_manager,
      create_admin_manager,
      edit_admin_manager,
      roles_manager,
      create_role_manager,
      permissions_manager,
    }

  },

})

</script>